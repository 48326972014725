let prod = "https://spark-express-api-prod.herokuapp.com/api"
let local = 'http://localhost:3000/api'
let sparkle_api = "https://com-sparkle-sparkle.herokuapp.com/api"

let onlineRiderApi = 'https://spark-express-rider-listing.herokuapp.com'// 'http://localhost:3001'


let API = prod
let SPARKLE_API = sparkle_api
let ONLINE_RIDER_API = onlineRiderApi
let SOCKET = onlineRiderApi
let SPARKLE_TOKEN = `eyJraWQiOiIxMFQrVjBsdGJmeHUxV0tDYjVicWNzYzlzXC9oUUtkYW5mQTh3RjRvblZ6RT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwNjUwNjU0NC1iYTFkLTQyMWMtODFiZi03MTA1MTc2NTM2NDEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfMVZrZUZPOUpFIiwiY3VzdG9tOmFjY291bnRUeXBlIjoiMSIsImNvZ25pdG86dXNlcm5hbWUiOiIwNjUwNjU0NC1iYTFkLTQyMWMtODFiZi03MTA1MTc2NTM2NDEiLCJhdWQiOiI3ZGxuNzdsMHNnb3R0cHNvc3Q5NTY0YWswOCIsImV2ZW50X2lkIjoiZWU4ZTg2MzItZTFhYy00ZTNhLTg4YWYtYjI0MzVkYmE5NGY4IiwiY3VzdG9tOm5hbWUiOiJNaWd1ZWwgQ2xpZW50IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2Mjg1NzUyMjUsImV4cCI6MTYyODY2MTYyNSwiaWF0IjoxNjI4NTc1MjI1LCJlbWFpbCI6Im1pZ3F1aW50b3MyM0BnbWFpbC5jb20ifQ.G6qx_gPDNQ8c2UMwj_VhOTZgkoYYJqWgLAfUXQf03ctOj1zjaHwH-qSspKUlhhRqphFVtO87lmCn4Tf4_UDs_67wLAKoCUM1KqZFE9K_eY4Mi2Bo7KCfH5sRy5PUzKMTY18LgUd1gx45oKsO8QocjoBMfz5KuCSZ6LoTRkpGdoaZkiWaf-hRVIRMA_uEKKrlMozVxblFbsg1BlMuyA6SdM8IqVbg4vUuNoYGMc-kIDcu8XQCCUyZ9SgQyNFuU8ZwiXmkUfd0fiyaM2PDmFd7tZajlf6OXwINZ7qiqB02HGAmk2-Qc61F-2SJu4XUqh2hdYeMosM71KFQFR605tjthA`

export { API, SPARKLE_API, SPARKLE_TOKEN, SOCKET, ONLINE_RIDER_API }
